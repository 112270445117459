<template>
  <div class="action-button d-flex py-2 mb-2 gap-3">
    <template v-if="cart.products.length">

      <div class="flex-grow-1 pl-4 pr-2">
        <button class="
          btn 
          btn-outline-primary
          px-1
          font-nunito font-14 font-700
          py-2 w-100" data-bs-toggle="modal" data-bs-target="#numericPad">
          <IconPlus class="me-2" color="#00B962" v-if="extraValue === 0" />
          <IconEdit class="me-2" v-else />
          Biaya Lainnya
        </button>
      </div>
      <div class="flex-grow-1 pr-4 pl-2">
        <button ref="showPopupPaymentMethods" class="d-none" type="button" data-target="#popupPaymentMethod"
          data-toggle="modal" />
        <button class="
          btn 
          btn-primary
          font-nunito font-14 font-700
          py-2 w-100
          px-1
          text-white" @click="goToPayment">
          Lanjut Bayar
          {{ usePoint ? formatRupiah(finalPrice - calculatedPoint, 'Rp') : formatRupiah(finalPrice, 'Rp') }}
        </button>
      </div>
    </template>
    <template v-else>
      <div class="flex-grow-1 px-4">

        <button class="
          btn 
          btn-outline-primary 
          font-nunito font-16 font-700
          py-2 w-100" data-bs-toggle="modal" data-bs-target="#popupQr">
          Pindai QR Code Pesanan
        </button>
      </div>
    </template>

  </div>
</template>

<script>
import IhsMixin from '@/mixins/ihs.mixins.js'
import { Modal } from 'bootstrap'

import IconEdit from '@/components/icons/IconEdit.vue'
import IconPlus from '@/components/icons/IconPlus.vue'



import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  mixins: [IhsMixin],
  components: {
    IconEdit,
    IconPlus
  },
  data() {
    return {
      emptyStockModal: ''
    }
  },
  computed: {
    ...mapState('cart', [
      'cart', 'extraValue'
    ]),
    ...mapState('payment', ['usePoint']),
    ...mapState('user', ['calculatedPoint']),
    ...mapGetters('cart', ['finalPrice']),
  },
  methods: {
    ...mapActions('product', ['getProductByBarcode']),
    ...mapActions('cart', ['getCart', 'getCartbyQr']),
    ...mapMutations('cart', ['SET_CART', 'SET_CART_PAYMENT']),
    ...mapMutations('numpad', ['SET_NUMPAD']),

    toogleNumpad() {
      this.SET_NUMPAD()
    },
    goToPayment() {

      const emptyProducts = this.cart.products.filter(product => {
        return !product.stock
      })

      if (emptyProducts.length) {
        this.emptyStockModal.show()

      } else {
        this.SET_CART_PAYMENT(this.cart)
        setTimeout(this.$router.push('/payment'), 1000)
      }


    }

  },
  mounted() {
    this.emptyStockModal = new Modal(document.getElementById('popupEmptyStockProduct'), {})
  },
}
</script>