<template>
    <div class="modal fade" id="voucherList" tabindex="-1" aria-labelledby="voucherList" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-voucher">
                <div class="modal-header">
                    <h5 class="modal-title">List Voucher</h5>
                    <button type="button" ref="closeVoucher" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="voucher-list d-flex flex-column flex-grow-1">
                        <div class="d-flex flex-column border-divider-grey bg-white sticky-stack-top mb-3 "
                            :style="'--top:0'">
                            <div class="d-flex pt-3 pb-13px">
                                <el-input v-model="search" placeholder="Masukan kode voucher" class="position-relative "
                                    clearable>
                                    <button slot="append"
                                        class="btn font-nunito font-700 font-12 d-flex border-primary  color-primary search-voucher-button"
                                        @click="applyVoucher('search')">
                                        <span class="pt-3px pb-3px">Terapkan</span>
                                    </button>
                                </el-input>
                            </div>

                            <div v-if="statusMessage" class="pb-3">
                                <div class="d-flex flex-column flex-grow-1 rounded px-3 py-3 mt-3" :class="statusCode === 200
                                    ? 'bg-primary-light color-primary-50'
                                    : 'bg-red-light color-red'
                                    ">
                                    <template v-if="statusCode === 200">
                                        <span class="font-nunito font-700 font-14">
                                            Berhasil Digunakan
                                        </span>
                                        <span class="font-roboto font-400 font-12">
                                            Anda mendapatkan potongan
                                            <template v-if="statusResponse.reduction_type === 'percent'">
                                                diskon {{ statusResponse.value }}%, hemat
                                            </template>
                                        </span>
                                        <span class="font-nunito font-700 font-14">
                                            {{ formatRupiah(formatVoucherAmmount(statusResponse, cartSubtotal)) }}
                                        </span>
                                    </template>

                                    <template v-else>
                                        <span class="font-nunito font-700 font-14">
                                            {{ statusResponse.errorTitle }}
                                        </span>
                                        <span class="font-roboto font-400 font-12">
                                            {{ statusResponse.errorMessage }}
                                        </span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <template v-if="voucherList.length">
                            <div class="font-nunito font-12 font-md-14 font-lg-16 font-700 px-16px">
                                Voucher Diskon
                            </div>

                            <div class="voucher-list-item">
                                
                                <template v-if="voucherList.length">
                                    <template v-for="voucher in voucherList">
                                        <div :key="voucher.id"
                                            class="voucher-card d-flex pointer w-100 mb-0 flex-column font-roboto bg-white position-relative"
                                            :class="[
                                                checkedData(voucher) ? 'active' : 'border',
                                                voucher.type === 'free_shipping_cost' ? 'disabled' : '',
                                                voucher.new_user ? isVoucherNewUserDisabled(voucher) ? 'disabled' : '' : '',
                                                voucher.regular_user ? isUserRegularDisabled(voucher) ? 'disabled' : '' : ''
                                            ]" @click.stop="selectAvailableVoucher(voucher)">
                                            <div v-if="voucher.regular_user"
                                                class="voucher-card__label font-nunito text-white mb-1"
                                                :class="{ 'voucher-card__label--disabled': isUserRegularDisabled(voucher) }">
                                                Khusus Regular Member
                                            </div>

                                            <span class="font-500 font-12 voucher-card__code">{{ voucher.code }}</span>
                                            <span class="font-400 font-11 mb-4px voucher-card__discount">
                                                Potongan {{ formatVoucherAmmountInfo(voucher) }}
                                            </span>
                                            <div class="d-flex mt-auto aling-items-center">
                                                <div
                                                    class="font-400 font-9 color-grey-50 mr-5px my-auto voucher-card__valid">
                                                    Berlaku : {{ voucher.date_end }}
                                                </div>
                                                <div v-if="showDetailLink(voucher)"
                                                data-bs-toggle="modal"
                                                data-bs-target="#voucherDetail"
                                                    class="font-9 color-primary lineheight-20 text-nowrap ms-2"
                                                    @click.stop="SET_VOUCHER_DETAIL(voucher)">
                                                    Lihat Detail
                                                </div>
                                            </div>
                                            <label class="voucher-card__radio radio radio-before position-absolute"
                                                :for="`voucher${voucher.code}`"
                                                :disabled=" voucher.type === 'free_shipping_cost'">
                                                <span class="radio__input">
                                                    <input :id="`voucher${voucher.code}`" type="radio"
                                                        class="radio-check__input" name="voucherDiscount"
                                                        :checked="checkedData(voucher)"
                                                        :disabled="( voucher.type === 'free_shipping_cost') || isVoucherNewUserDisabled(voucher) || isUserRegularDisabled(voucher)"
                                                        @click="selectAvailableVoucher(voucher)">
                                                    <label :for="`voucher${voucher.code}`" class="radio-check">
                                                        <img class="radio-check__img" src="@/assets/icons/check-icon.svg"
                                                            draggable="false" alt="Icon Check">
                                                    </label>
                                                </span>
                                            </label>
                                        </div>
                                    </template>
                                </template>
                                
                                <template v-else>
                                    <div class="empty-voucher">
                                        <EmptyVoucher class="empty-voucher__img mx-auto" />
                                        <div class="empty-voucher__title">
                                            Tidak Ada Voucher
                                        </div>
                                        <div class="empty-voucher__desc">
                                            Kembali lagi besok untuk mendapatkan voucher menarik lainnya
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                        <img class="radio-check__img" src="@/assets/icons/check-icon.svg"
                         draggable="false" alt="Icon Check">

                    </div>
                    <CartVoucherNavigation   @delete-voucher="deleteVoucher" :onPreview="true" :previewVoucher="previewVoucherSelected" />
                    <button class="btn btn-primary w-100 d-block py-2 font-600" @click="applyVoucher">Gunakan Voucher</button>
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import IhsMixin from "@/mixins/ihs.mixins.js";
import CartVoucherNavigation from '@/components/cart/partials/CartVoucherNavigation.vue'

export default {
    mixins: [IhsMixin],
    components: {CartVoucherNavigation},
    data() {
        return {
            search: '',
            statusMessage: false,
            statusCode: '',
            statusResponse: {},
        }
    },
    computed: {
        ...mapState('voucher', ['voucherList', 'previewVoucherSelected']),
        ...mapState('cart', ['cart', 'voucher']),
        ...mapState('user', ['customerSelected']),
    },
    watch: {
        cart: {
            handler(cart) {
              cart.vouchers.forEach(voucher => {
                if (voucher.type !== 'free_shipping_cost') {
                  this.SET_PREVIEW_VOUCHER_SELECTED(voucher)
                } 
                return
              })
            },
            deep: true
        }
    },
    mounted() {

        this.getVouchers({
          params: {
            phone_number: this.customerSelected.phone_number
          } 
        })
    },
    methods: {
        ...mapActions('voucher', ['getVouchers']),
        ...mapActions('cart', ['useVoucher', 'getCart']),

        ...mapMutations('cart', ['SET_VOUCHER']),
        ...mapMutations('voucher', ['SET_VOUCHER_DETAIL', 'SET_PREVIEW_VOUCHER_SELECTED']),
        selectAvailableVoucher(data) {
            if (!Object.keys(data).length) {
                data = {}
                this.statusMessage = false

                return
            }
            if (data.type === 'free_shipping_cost' ) {
                this.$message({
                    showClose: true,
                    message: 'Ambil di Toko tidak dapat menggunakan voucher ini',
                    type: 'error'
                })

                return
            }

            if (data.new_user) {
                if (this.isVoucherNewUserDisabled(data)) {
                    data = {}
                    this.statusMessage = false
                }
            }

            if (data.regular_user) {
                if (this.isUserRegularDisabled(data)) {
                    data = {}
                    this.statusMessage = false
                }
            }

            if (this.checkedData(data)) {
                data = {}
                this.statusMessage = false
                
            } 
            this.SET_PREVIEW_VOUCHER_SELECTED(data)
            return
        },

        isVoucherNewUserDisabled(voucher) {
            if (voucher.new_user) {
                if (this.customerSelected.is_new_user) {
                return false
                }
            }
            return true
        },

    
    checkedData(data) {
    if (Object.keys(this.previewVoucherSelected).length) {
        return this.previewVoucherSelected.id === data.id

     }

     return false
    },

    isUserRegularDisabled(voucher) {
      if (voucher.regular_user) {
        if (this.customerSelected.membership === 'regular_member') {
          return false
        }
      }
      return true
    },
    showDetailLink(voucher) {
      if (voucher.new_user) {
        if (!this.customerSelected.is_new_user) {
          return false
        }
      }

      if (voucher.regular_user) {
        if (this.customerSelected.membership !== 'regular_member') {
          return false
        }
      }

      return true
    },
    async applyVoucher(type = 'default') {
      try {
        let code = ''

        if (type === 'search') {
          code = this.search
        } else {
          code = this.previewVoucherSelected.code
        }

        await this.useVoucher({
          data: {
            voucher_code: code,
            phone_number: this.customerSelected.phone_number
          }
        })
        .then(response => {
          if (!response.data.status_code === 200) return
            this.$refs['closeVoucher'].click()

            this.getCart({id: this.customerSelected.phone_number}).then(() => {
              this.$message({
                showClose: true,
                message: 'Berhasil menggunakan voucher',
                type: 'success'
              })
            })
            

           })
          .catch(error => {
              this.statusMessage = true
              this.statusCode = error.response.data.status_code
              switch (error.response.data.message) {
                case 'expired':
                  this.statusResponse = {
                    errorTitle: 'Voucher Kadaluarsa',
                    errorMessage:
                      'Voucher yang Anda masukkan sudah kadaluarsa. Mohon untuk gunakan kode voucher yang lain.'
                  }
                  break
                case 'didnt_reach_minimum_amount':
                  this.statusResponse = {
                    errorTitle: 'Tidak Dapat Digunakan',
                    errorMessage:
                      'Pesanan Anda kurang dari batas minimum pembelian. Belanja lagi untuk menggunakan voucher ini.'
                  }
                  break
                case 'total_price_not_accepted':
                  this.statusResponse = {
                    errorTitle: 'Tidak Dapat Digunakan',
                    errorMessage:
                      'Pesanan Anda kurang dari batas minimum pembelian untuk Menggunakan Voucher (Rp. 10.000). Belanja Lagi untuk menggunakan voucher ini'
                  }
                  break
                case 'limit_reached':
                  this.statusResponse = {
                    errorTitle: 'Tidak Dapat Digunakan',
                    errorMessage:
                      'Voucher sudah melebihi batas penggunaan. Silakan gunakan voucher lain.'
                  }
                  break
                case 'not_new_user':
                  this.statusResponse = {
                    errorTitle: 'Tidak Dapat Digunakan',
                    errorMessage:
                      'Voucher hanya berlaku untuk pengguna baru. Mohon untuk gunakan kode voucher yang lain.'
                  }
                  break
                case 'not_regular_user':
                  this.statusResponse = {
                    errorTitle: 'Tidak Dapat Digunakan',
                    errorMessage:
                      'Voucher hanya berlaku untuk pengguna Regular Member. Mohon untuk gunakan kode voucher yang lain. '
                  }
                  break
                default:
                  this.statusResponse = {
                    errorTitle: 'Tidak ditemukan',
                    errorMessage:
                      'Voucher yang Anda masukkan tidak dapat digunakan. Mohon cek kembali kode voucher yang Anda masukkan.'
                  }
                  break
              }
            })
        
      } catch (error) {
        console.log(error)
      }
    },
    deleteVoucher() {
      
      this.SET_PREVIEW_VOUCHER_SELECTED({})

    },
    
}
}
</script>
  

<style lang="scss">

.modal-voucher {
    width: 432px;
}
.voucher-list {
  
  .el-input__inner {
    height: 38px;
    line-height: 38px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .el-input-group {
    &__append {
      padding: 0;
      background: none;
      border: none;

      button {
        color: $black;
        border: 1px solid $primary;

        &:active {
          transform: none;
        }
      }
    }
  }
}

.voucher-list-item {
margin-top: 17px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 18px;
}

.voucher-card {
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(218, 218, 218, 0.25);
    padding: 8px;
  &__label {
    background-color: $primary;
    font-size: 9px;
    padding: 4px 2px 4px 7px;
    width: 55%;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    margin-left: -8px;

    &--disabled {
      background-color: $grey-50;
      color: white;
    }
  }

  &.disabled {
    background-color: rgba(224, 224, 224, 0.2) !important;

    .voucher-card__code,
    .voucher-card__discount {
      color: $grey-90 !important;
    }

    .voucher-card__valid {
      color: $grey-50
    }
  }

  &__radio {
    right: 0;
    transform: translateX(-50%);

    &:disabled,
    .disabled {
      cursor: not-allowed;
      background-color: rgba(224, 224, 224, 0.2) !important;

      .radio-check {
        background-color: rgba(224, 224, 224, 0.2) !important;
      }

      ~.radio-check {
        cursor: not-allowed;
      }
    }
  }

  &.active {
    border: 1px solid $primary !important;
  }

  .radio-check {
    margin-left: auto;
    text-align: center;
    width: 16px;
    height: 16px;
    border: 1px solid $grey-10;
    border-radius: 50%;
    cursor: pointer;

    &__img {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 35%;
      left: 50%;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .radio-check__input {
    display: none;

    &:checked+.radio-check {
      background: $primary;
      border: 1px solid $primary;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.empty-voucher {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;

  &__img {
    width: 275px;
    height: 229px;
    margin-bottom: 8px;
  }

  &__title {
    color: $blue-dark-50;
    margin-bottom: 8px;
    font: normal normal 700 14px/21.82px "Nunito", sans-serif;
  }

  &__desc {
    font: normal normal normal 12px/19.6px "Roboto", sans-serif;
  }
}

.voucher-card__content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .voucher-card__title {
    font-size: 14px;
    font-weight: 700;
    color: $grey-50;
  }

  .voucher-card__price {
    font-size: 14px;
    font-weight: 700;
    color: $blue-50;
  }
}

.search-voucher-button {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}


</style>
