<template>
  <div class="modal fade" id="startShift" tabindex="-1" aria-labelledby="startShift" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Mulai Shift</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <span class="font-14 font-roboto font-400">Masukkan Modal awal pada kasir jika ada</span>
        <el-form :inline="true" class="mt-3 mx-auto">
            <el-form-item class="mb-0 w-100 d-flex" >
                <template v-slot:label>
                    <span class="font-14 font-roboto font-400 color-black">
                        Modal Awal :
                    </span>
                </template>
                <el-input v-model="formattedAmount" class="w-100 "></el-input>

            </el-form-item>
            <button type="button" class="btn btn-primary text-white font-700 font-nunito w-100 mt-4" data-bs-dismiss="modal" @click="startShift"> Mulai Shift</button>

        </el-form>
      </div>
   
    </div>
  </div>
</div>
</template>

<script>
import ihsMixins from '@/mixins/ihs.mixins';

export default {
    mixins: [ihsMixins],
    data() {
        return {
            initialBalance: 'Rp.0'
        }
    },
    computed : {
      formattedAmount: {
            get() {
              return this.initialBalance;
            },
            set(newValue) {
              this.initialBalance = this.formatRupiah(newValue, "Rp.");
            }
          }
    },
    methods: {
      async startShift() {
        await this.$emit('start-shift', this.unformatRupiah(this.initialBalance))
        this.initialBalance = ''
      }
    }
}
</script>

<style lang="scss" scoped>
.modal-dialog {
    width: 382px;
}
</style>