<template>
  <div class="modal fade" id="endShift" tabindex="-1" aria-labelledby="endShift" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Akhiri Shift</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <span class="font-14 font-roboto font-400">Shift akan diakhiri. Laporan shift akan dibuat sesuai dengan transaksi, modal awal, dan setoran kasir hari ini.</span>
            <div class="d-flex gap-3">
              <button type="button" class="btn btn-outline-primary  font-700 font-nunito w-100 mt-4" ref="closeModal" data-bs-dismiss="modal"> Batalkan</button>
              <button type="button" class="btn btn-primary text-white font-700 font-nunito w-100 mt-4"  @click="endCurrentShift"> Akhiri Shift</button>
            </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState , mapMutations, mapActions} from 'vuex'
import IhsMixin from "@/mixins/ihs.mixins.js";

export default {
	mixins: [IhsMixin],
    data() {
      const checkAmount = (_, value, callback) => {
        if (parseInt(value.replace(/\D/g, "")) === 0) {
          callback(new Error("Masukkan Setoran bank"));
        } else {
          callback();
        }
      };
      return {
        form: {
          cashDeposit: 'Rp.0'
        },
        formRules: {
          cashDeposit: [{ validator: checkAmount, trigger: "change" }],
        }
      }
    },
    computed : {
      ...mapState('shift', ['shift']),
      
      formattedAmount: {
            get() {
              return this.form.cashDeposit;
            },
            set(newValue) {
              this.form.cashDeposit = this.formatRupiah(newValue, "Rp.");
            }
          }
    },
    methods: {
      ...mapMutations('user', ['SET_SHIFT_START']),
      ...mapActions('shift', ['endShift']),
      ...mapActions("cart", ["emptyCart"]),
      ...mapMutations("cart", ["SET_CART_DRAFT"]),
      async endCurrentShift() {
        try {
          const payload = {
            data: {
              id: this.shift.id,
            }
          };

          const response = await this.endShift(payload);
          if (response.data.status_code === 200) {
            this.SET_CART_DRAFT([])
            await this.emptyCart({data: {phone_number: 111000}})
            this.$refs["closeModal"].click()
          }
        } catch (error) {
          console.log(error);
        }
      
      }
    }
}
</script>

<style lang="scss" scoped>
.modal-dialog {
    width: 382px;
}
</style>