<template>
    <div class="modal fade" id="voucherDetail" tabindex="-1" aria-labelledby="voucherList" aria-hidden="true" ref="modalDetail">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">List Voucher</h5>
                    <button type="button"  class="btn-close" data-bs-toggle="modal" data-bs-target="#voucherList" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0">
                    <template v-if="Object.keys(voucherDetail).length">
              <div class="voucher-info pb-80px">
                <div class="content ">
                  <div class="content__heading bg-primary py-2 px-3 d-flex justify-content-between">
                    <span class="font-nunito font-16 font-700 color-white">
                      {{ voucherDetail.code }}
                    </span>
                    <div
                      class="content__heading__label bg-white font-nunito font-10 font-700 w-31px h-31px color-primary rounded-pill d-flex align-items-center">
                      <img src="@/assets/icons/voucher-icon-2.svg" alt="">
                    </div>
                  </div>
                  <div class="content__sort-desc bg-white py-2 px-3">
                    <div class="d-flex mb-1">
                      <div class="d-flex">
                        <img src="@/assets/icons/cart-icon.svg" class="my-auto me-2">
                        <span class="font-nunito font-700 font-12 color-grey-70 my-auto">Min. Pembelian</span>
                      </div>
                      <div class="ms-auto">
                        
                        <span class="font-roboto font-12">{{ voucherDetail.minimum_amount ?
                          formatRupiah(
                            voucherDetail.minimum_amount.toString(),
                            'Rp'
                          ) : '-'
                        }}</span>
                      </div>
                    </div>
                    <div class="d-flex mb-1">
                      <div class="d-flex">
                        <img src="@/assets/icons/clock-icon.svg" class="my-auto me-2">
                        <span class="font-nunito font-700 font-12 color-grey-70 my-auto">Masa Berlaku</span>
                      </div>
                      <div class="ms-auto">
                        <span class="font-roboto font-12">
                          <template v-if="voucherDetail.date_start === voucherDetail.date_end">
                            {{ formatDateId(voucherDetail.date_end, true) }}
                          </template>
                          <template v-else>
                            {{ formatDateId(voucherDetail.date_start) }} -
                            {{ formatDateId(voucherDetail.date_end, true) }}
                          </template>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="voucher-brief flex-grow-1 font-14 px-3 overflow-auto color-secondary">
                <div v-if="voucherDetail.name" class="mb-3">
                  <span class="font-nunito font-400 font-16 font-700 color-blue-dark-50 "
                  >{{
                      voucherDetail.name
                    }}</span>
                </div>
                <div v-if="voucherDetail.description" class="mb-3">
                  <span class="font-nunito font-700 mb-8px d-block">
                    Deskripsi
                  </span>
                  <span class="font-roboto font-400 font-12">{{
                    voucherDetail.description
                  }}</span>
                </div>
                <div v-if="voucherDetail.term_and_condition" class="pb-80px">
                  <span class="font-nunito font-700 mb-8px d-block ">
                    Syarat & Ketentuan
                  </span>
                  <span class="font-roboto font-12 font-400" v-html="voucherDetail.term_and_condition" />
                </div>
              </div>

              <div class="shadow-grey d-flex bg-white p-3 position-sticky">
                <div class="font-14">
                  <span class="font-nunito font-16 font-700 d-block font-">
                    {{ voucherDetail.code }}
                  </span>
                  <span class="font-roboto font-12 font-400">
                    Potongan {{ voucherDetail.reduction_type === 'amount' ? formatRupiah(
                      voucherDetail.value.toString(),
                      'Rp'
                    ) : (voucherDetail.value + '%') }}
                  </span>
                </div>
                <div class="my-auto ms-auto">
                  <button class="btn btn-primary font-nunito font-700 font-12" @click="handleCopy">
                    Salin
                  </button>
                </div>
              </div>
            </template>
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
import { mapState } from 'vuex'
import ihsMixins from '@/mixins/ihs.mixins'

export default {

  mixins: [ihsMixins],

  computed: {
    ...mapState('voucher', ['voucherDetail'])
  },
  mounted() {
            let modalDetail = document.getElementById('voucherDetail')

            modalDetail.addEventListener('hidden.bs.modal', function () {
                let elements = document.getElementsByClassName('modal-backdrop fade show');
                while (elements.length > 0) {
                    elements[0].parentNode.removeChild(elements[0]);
                }
            })
    },
  methods: {
    handleCopy() {
      navigator.clipboard.writeText(this.voucherDetail.code)
      this.$message({
        showClose: true,
        message: 'Kode voucher berhasil disalin',
        type: 'success'
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.voucher-info {
    padding-bottom: 80px;
  .content {
    height: 90px;
    padding: 28px 16px;
    background: linear-gradient(217.53deg, #FFFFFF 8.69%, #D0EFFA 49.33%, #AFE6CC 86.25%);


    &.voucher-bg {
      background-color: #C0E3FA;
    }

    &__heading {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    &__sort-desc {
      min-height: 80px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      box-shadow: 0 4px 8px rgba(218, 218, 218, 0.25);
    }
  }
}

.voucher-button {
  box-shadow: 0px -4px 8px rgba(105, 199, 231, 0.25);
}

.modal-content {
  min-height: 80%;
}
</style>
