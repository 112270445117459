<template>
  <div class="customer-list">
    <div 
      v-for="user in customerList" 
      :key="user.id"
      @click="selectCustomer(user)"
      class="user-list d-flex p-3">
      <div class="user-avatar my-auto">
        <img
          :src="imageUser(user.image, user.gender)"
          alt="Avatar">
      </div>
      <div 
        class="
        d-flex 
        flex-column 
        d-inline-block
        font-16
        ms-3">
        <span class="font-nunito font-700">
          {{user.name}}
        </span>
        <span class="font-roboto">
          0{{user.phone_number ? user.phone_number : '-'}}
        </span>
        <span class="font-roboto">
          {{user.email ? user.email : '-'}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import IhsMixin from '@/mixins/ihs.mixins.js'
import { mapState, mapActions ,mapMutations } from 'vuex'

export default {
  mixins: [IhsMixin],
  computed: {
    ...mapState('user', ['customerList'])
  },
  methods: {
    ...mapActions('cart', ['getCart', 'sendAdditionalCost']),
    ...mapActions('payment', ['setPaymentUsePoint']),
    ...mapMutations('user', ['SET_SELECTED_CUSTOMER']),
    ...mapMutations('cart', ['DELETE_CART_PAYMENT', 'SET_CART_EXTRA_VALUE']),
    ...mapMutations('payment', ['SET_USE_POINT']),
    
    selectCustomer(user){
      this.DELETE_CART_PAYMENT()
      this.SET_USE_POINT(false)
      this.setPaymentUsePoint(0)
      this.sendAdditionalCost(0)
      this.SET_CART_EXTRA_VALUE(0)
      this.getCart({id: user.phone_number}).then(
          this.SET_SELECTED_CUSTOMER(user)
      )
    }
  }
}
</script>

<style lang="scss" scoped>

.customer-list {
  height: 300px;
  overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px !important;
    }

    /* Track */
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00B962; 
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #0ac26c; 
    }
  
}
.user-list:hover{
  cursor: pointer;
  background: #F6FCFF;
}

.user-avatar{
  img{
    @include image-properties(67px, 67px, 50%);
  }
}
</style>