<template>
	<div v-dragscroll class="category-list">
		<template v-if="categoryLoading">
			<category-skeleton v-for="i in 9" :key="i.id" />
		</template>

		<template v-else>
			<div class="d-inline-block text-nowrap category-card">
				<input id="category-all" name="category" ref="category-all'" type="radio" @click="selectCategory('')"
					value="all" :checked="Object.keys(productCategorySelected).length === 0" />
				<label for="category-all">
					<div class="d-flex flex-column">
						<div class="category-icon mx-auto">
							<img src="@/assets/icons/all-category.svg" class="all">
						</div>
						<div class="category-name font-roboto font-12 mt-4">
							Semua
						</div>
					</div>
				</label>
			</div>
			<div v-for="(category, index) in categories" :key="category.id" class="d-inline-block category-card">
				<input :id="'category-' + index" name="category" :ref="'category-' + index" type="radio"
					@click="selectCategory(category.slug)" :value="category.name"
					:checked="productCategorySelected === category.slug" />
				<label :for="'category-' + index" :class="(index + 1) < productCategory.length ? '' : 'mr-0'">
					<div class="d-flex flex-column">
						<div class="category-icon mx-auto">
							<img :src="category.icon_image" alt="icon category" @error="defaultImageProduct">
							<!-- <img src="@/assets/icons/all-category.svg"> -->
						</div>

						<div class="category-name font-roboto font-12 mt-3">
							{{ category.name }}
						</div>
					</div>
				</label>
			</div>
		</template>
	</div>
</template>

<script>
import IhsMixin from "@/mixins/ihs.mixins.js";
import { dragscroll } from "vue-dragscroll";
import { mapState, mapMutations, mapActions } from "vuex";

import CategorySkeleton from "@/components/skeleton/CategorySkeleton.vue";
export default {
	mixins: [IhsMixin],
	directives: {
		dragscroll
	},
	components: {
		CategorySkeleton
	},
	computed: {
		...mapState("home", ["sidebarItemActive"]),
		...mapState("category", ["categories", "categoryLoading"]),
		...mapState("product", [
			"productCategory",
			"productCategorySelected",
			"productCategoryLoading"
		]),
		filterQuery() {
			return this.$route.query;
		}
	},
	methods: {
		...mapMutations("product", ["SET_SELECTED_PRODUCT_CATEGORY"]),
		...mapActions("category", ["getCategories"]),
		checkSelectedCategory() {
			if (this.filterQuery.category) {
				this.SET_SELECTED_PRODUCT_CATEGORY(this.filterQuery.category);
			}
		},
		selectCategory(category) {
			this.SET_SELECTED_PRODUCT_CATEGORY(category);
			if (this.$router.currentRoute.query.category !== category) {
				this.setToQuery({ category: category });
			}
		}
	},
	async created() {
		const payload = {
			params: {
				perPage: -1
			}
		}
		await this.getCategories(payload);
		await this.checkSelectedCategory();
	}
};
</script>

<style lang="scss" scoped>
@mixin custom-radio($text-color, $border-color, $background) {
	color: $text-color;
	background: $background;
	border: 2px solid $border-color;
	border-radius: 6px;
	padding: 8px;
	margin-top: 10px;
}

@mixin custom-input-label($color) {
	input[type="radio"] {
		display: none;
	}

	label {
		@include custom-radio(#c1c1c2, $white, $white);
		display: inline-block;
		cursor: pointer;
		width: 84px;
		display: -webkit-inline-box;
		height: 100%;

		.label-color {
			width: 12px;
			height: 12px;
			margin: auto 4px auto 0;
			border-radius: 2px;
		}
	}

	input[type="radio"]:checked+label {
		@include custom-radio($color, #00b962, #00b962);

		.category-icon {
			border: 1px solid #00b962;
			background: white;
		}
	}
}

.category-list {
	display: flex;
	overflow: auto;
	gap: 12px;
	padding: 8px 0 26px 0;
	@include custom-input-label(white);


	.category-icon {
		border: 1px solid #ededed;
		border-radius: 4px;
		padding: 12px;
		margin-bottom: 8px;

		img {
			width: 38px;
			height: 38px;
			object-fit: cover;

			&.all {
				width: 38px;
				height: 38px;
			}
		}
	}

	.category-name {
		word-break: break-all;
		text-align: center;
	}
}
</style>